import React from 'react'
import { graphql, Link } from 'gatsby'
import { Date, RichText } from 'prismic-reactjs'
import Img from "gatsby-image/withIEPolyfill";

import Layout from "../components/layout"
import {TikLink} from "../components/helper"
import {blogDateYear, blogDateWithoutYear} from "../utils/blogDate";

import SEO from "../components/seo"
import Share from "../components/share"
import logo from "../images/made-by-logo.svg";
import { rootPath, blogPath } from '../utils/linkResolver';



const Post = props => {
  const location = props.location;
  const lang = props.pageContext.lang;
  const data = props.data;
  const doc = props.data.prismic.allPosts.edges[0];
  const t = props.data.prismic.allBlogpages.edges[0].node;
  if(!doc) return null;

  const { siteUrl, twitterHandle } = props.data.site.siteMetadata;
  const fullURL = `${siteUrl}${props.location.pathname}`;

  function fluidWithCustomSize(fl) {
    fl.sizes = '(min-width: 500px) 66.66vw, 100vw'
    return fl;
  }
  

  return (
    <Layout location={location} data={data} activeKey={'blog'} lang={lang}>
        <SEO 
          title={doc.node.title && RichText.asText(doc.node.title)} 
          description={RichText.asText(doc.node.abstract)}
          image={doc.node.image && doc.node.image.square.url} />

        <article className="blog-article">
          <div className="section-logo">
            <Link className="made-by-logo" to={rootPath({lang})}>
              <img alt="made.by Logo" src={logo} />
            </Link>
          </div>

          <div className="blog-article-container">
            <div className="blog-article-back">
              <TikLink to={blogPath({lang})} className="link-magenta">{t.post_all_articles}</TikLink>
            </div>
            <div className="blog-article-headline">
              {doc.node.title && <>
                {RichText.render(doc.node.title)}
                <div className="blog-article-headline-bg">{RichText.render(doc.node.title)}</div>
              </>}
              
            </div>

            {doc.node.show_cover_image_in_detail && doc.node.imageCover ? <div className="blog-article-cover">
                <div className="blog-article-cover-meta">
                  <div className="blog-article-cover-meta-date">
                    {blogDateWithoutYear(doc.node.date, lang)}<br />
                    {blogDateYear(doc.node.date, lang)}
                  </div>
                  { doc.node.author && <div className="blog-article-cover-author">
                    { t.post_author && t.post_author.replace('%{author}', `${doc.node.author.firstname} ${doc.node.author.lastname}`)}
                  </div>}
                </div>
                <div className="blog-article-cover-img-container">
                  {/* <img className="blog-article-cover-img" 
                    srcSet={`${doc.node.cover.landscape360x240.url} 360w, ${doc.node.cover.landscape540x360.url} 540w, ${doc.node.cover.landscape720x480.url} 720w,${doc.node.cover.landscape960x640.url} 960w`}
                    sizes="(min-width: 500px) 66.66vw, 100vw"
                    src={doc.node.cover.landscape960x640.url} alt={doc.node.cover.alt} /> */}
                  <Img
                      className="blog-article-cover-img"
                      fluid={fluidWithCustomSize(doc.node.imageCover.childImageSharp.fluid)}
                      alt={doc.node.image && doc.node.image.alt}
                  />
                  <Share className="blog-article-cover-share" url={fullURL} title={doc.node.title && RichText.asText(doc.node.title)} twitterHandle={twitterHandle} />
                </div>
              </div> : <div className="blog-article-meta">
                <div className="blog-article-meta-date">{new Intl.DateTimeFormat('de', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Date(doc.node.date))}</div>
                { doc.node.author && <div className="blog-article-meta-author">
                    { t.post_author && t.post_author.replace('%{author}', `${doc.node.author.firstname} ${doc.node.author.lastname}`)}
                  </div>}
                <Share className="blog-article-meta-share" url={fullURL} title={doc.node.title && RichText.asText(doc.node.title)} twitterHandle={twitterHandle} />
              </div>}

            <div className="blog-article-abstract">
              {RichText.render(doc.node.abstract)}
            </div>
            <div className="blog-article-content">
              {RichText.render(doc.node.content)}
            </div>

            <div className="blog-article-back">
              <TikLink to={blogPath({lang})} className="link-magenta">{t.post_all_articles}</TikLink>
            </div>
          </div>
        </article>
    </Layout>
  );
};


export const query = graphql`
query PostQuery($uid: String, $lang: String = "de-de") {
  site {
    siteMetadata {
      title
      description
      author
      siteUrl
      twitterHandle
    }
  }
  prismic {
    ...fragmentPrismicLayout

    allBlogpages(lang: $lang) {
      edges {
        node {
          post_all_articles
          post_author
        }
      }
    }

    allPosts(uid: $uid, lang: $lang) {
      edges {
        node {
          title
          date
          abstract
          show_cover_image_in_detail
          content
          image
          imageCover: imageSharp(crop: "landscape") {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          author {
            _linkType
            ... on PRISMIC_Authors {
              firstname
              lastname
            }
          }
        }
      }
    }
  }
}
`;



export default Post;